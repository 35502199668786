import Link from 'next/link'
import type { FC, MouseEventHandler, ReactNode } from 'react'
import { LoadingIndicator } from '../icons/LoadingIndicator'

function Indicator() {
  return <LoadingIndicator className="relative bottom-0.5 mr-3 inline-block h-5 w-5" />
}

export function getButtonClasses({
  className,
  variant = 'outline',
  color,
  bottomBorder,
  border,
  background,
  stretched = false,
  isDisabled = false,
  size = 'lg',
}: {
  className?: string
  variant?: 'fill' | 'outline'
  color?: string
  bottomBorder?: string | null
  border?: string
  background?: string
  stretched?: boolean
  isDisabled?: boolean
  size?: 'lg' | 'sm'
}) {
  const variants = {
    fill: [
      border && `border-2 ${border}`,
      'hover:opacity-90',
      bottomBorder !== null && 'border-b-8',
      'focus:ring-4',
      // conditional
      background || 'bg-[#260080]',
      color || 'text-white',
      bottomBorder !== undefined ? bottomBorder : 'border-[#3B15AD]',
    ],
    outline: [
      'border-2',
      'focus:ring-2',
      // conditional
      background || 'bg-white',
      color || 'text-[#191726]',
      bottomBorder || 'border-[#9D9CA6]',
    ],
  }

  const common = [
    'no-webkit-appearance', // https://stackoverflow.com/questions/5438567/css-submit-button-weird-rendering-on-ipad-iphone
    'text-center',
    'rounded-md',
    'px-6',
    size === 'lg' ? 'py-3' : 'py-2',
    'focus:outline-none',
    size === 'lg' ? 'text-xl' : 'text-lg',
    'leading-loose',
    size === 'lg' ? 'sm:text-2xl' : 'sm:text-xl',
    'font-bold',
    'focus:ring-primaryLegacy-lighter',
    'transition',

    // conditional
    className && className,
    stretched && 'w-full',
    isDisabled && 'cursor-not-allowed opacity-75',
  ]

  return [...common, ...variants[variant]].join(' ')
}

export interface ButtonProps {
  color?: string
  bottomBorder?: string | null
  background?: string
  variant?: 'fill' | 'outline'
  type?: 'submit' | 'button' | 'reset' | undefined
  stretched?: boolean
  className?: string
  loading?: boolean
  disabled?: boolean
  href?: string
  replace?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  size?: 'lg' | 'sm'
  border?: string
  children?: ReactNode
}

const Button: FC<ButtonProps> = ({
  color,
  bottomBorder,
  background,
  variant = 'outline',
  children,
  type = 'button',
  stretched = false,
  className = '',
  loading = false,
  disabled,
  href,
  replace,
  onClick,
  border,
  size,
  ...rest
}) => {
  const isDisabled = loading || disabled
  const classNames = getButtonClasses({
    className,
    variant,
    color,
    background,
    bottomBorder,
    stretched,
    border,
    isDisabled,
    size,
  })
  const ButtonChildren = (
    <>
      {loading && <Indicator />}
      {children}
    </>
  )

  if (href === undefined) {
    return (
      <button type={type} className={classNames} disabled={isDisabled} onClick={onClick} {...rest}>
        {ButtonChildren}
      </button>
    )
  }

  return (
    <Link href={href} replace={replace} type={type} className={classNames} {...rest}>
      {ButtonChildren}
    </Link>
  )
}

export { Button }
